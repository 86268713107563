import { CameraControls } from "@react-three/drei";
import CameraControlsImpl from "camera-controls";

export default function Controls() {
	return (
		<CameraControls
			makeDefault
			// Do not let camera go below 0
			maxPolarAngle={Math.PI / 2}
			// Disable pan
			mouseButtons={{
				left: CameraControlsImpl.ACTION.ROTATE,
				right: CameraControlsImpl.ACTION.TRUCK,
				wheel: CameraControlsImpl.ACTION.ZOOM,
				middle: CameraControlsImpl.ACTION.TRUCK,
			}}
		/>
	);
}
