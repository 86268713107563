import { type FunctionComponent } from "react";
import { type Vector3 } from "three";

interface SpacedArrayProps {
	count: number;
	offset: Vector3;
	children: (position: Vector3, i: number) => React.ReactNode;
}

// TODO: memoization?
const SpacedArray: FunctionComponent<SpacedArrayProps> = ({ count, offset, children }) => {
	return <group>{Array.from(Array(count), (_, i) => children(offset.clone().multiplyScalar(i), i))}</group>;
};

export default SpacedArray;
