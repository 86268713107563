import { type Textures, useDeferredTexture, type TexturesType } from "@/hooks/useDeferredTexture";
import { type ReactNode, useMemo, useLayoutEffect, FunctionComponent } from "react";

interface ClonedTexture {
	textureName: Textures;
	onLoad?: (textures: ReturnType<typeof useDeferredTexture>) => void;
	children?: (textures: ReturnType<typeof useDeferredTexture>) => ReactNode;
}

const ClonedTexture: FunctionComponent<ClonedTexture> = ({ children, onLoad, textureName }) => {
	const originalTextures = useDeferredTexture(textureName);
	const clonedTextures = useMemo(() => {
		const clonedTextures = {} as TexturesType;

		for (const [key, texture] of Object.entries(originalTextures)) {
			const clonedTexture = texture.clone();
			clonedTextures[key as keyof typeof originalTextures] = clonedTexture;
		}

		return clonedTextures;
	}, [originalTextures]);

	useLayoutEffect(() => {
		onLoad?.(clonedTextures);
	}, [clonedTextures, onLoad]);

	return children?.(clonedTextures);
};

export default ClonedTexture;
