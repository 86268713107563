import { type MeshProps } from "@react-three/fiber";
import { forwardRef, useLayoutEffect, useMemo, useRef } from "react";
import { type ExtrudeGeometry, type Mesh, Shape } from "three";

interface BeveledBox extends Omit<MeshProps, "args"> {
	width: number;
	height: number;
	depth: number;
	bevelThickness: number;
}

const BeveledBox = forwardRef<Mesh, BeveledBox>(function Plank(
	{ width, height, depth, bevelThickness, children, ...meshProps },
	meshRef
) {
	const geometryRef = useRef<ExtrudeGeometry>(null);

	const shape = useMemo(() => {
		const shape = new Shape();

		shape.moveTo(0, 0);
		shape.lineTo(width, 0);
		shape.lineTo(width, height);
		shape.lineTo(0, height);
		shape.lineTo(0, 0);

		return shape;
	}, [width, height]);

	const extrudeSettings = useMemo(
		() => ({
			steps: 1,
			depth: depth - 2 * bevelThickness,
			bevelEnabled: bevelThickness > 0,
			bevelThickness,
			bevelSize: bevelThickness,
			bevelOffset: 0,
			bevelSegments: 1,
		}),
		[depth, bevelThickness]
	);

	useLayoutEffect(() => {
		geometryRef.current?.center();
	}, [shape, extrudeSettings]);

	return (
		<mesh ref={meshRef} {...meshProps}>
			<extrudeGeometry ref={geometryRef} args={[shape, extrudeSettings]} />
			{children}
		</mesh>
	);
});

export default BeveledBox;
