import { MeshProps } from "@react-three/fiber";
import { FunctionComponent, ReactNode, useMemo } from "react";
import { Geometry, Base } from "@react-three/csg";
import { useLeva } from "@/hooks/useLeva";
import { MeshStandardMaterial } from "three";
import { TexturesType } from "@/hooks/useDeferredTexture";

interface WallProps extends MeshProps {
	width: number;
	height: number;
	thickness?: number;
	textures?: TexturesType;
	children?: ReactNode;
}

const defaultMaterial = new MeshStandardMaterial({ color: "grey" });

const Wall: FunctionComponent<WallProps> = ({ width, height, thickness, textures, children, ...meshProps }) => {
	const { wireframe, wallTextureRoughness: roughness } = useLeva();

	const materials = useMemo(() => {
		defaultMaterial.wireframe = wireframe;
		defaultMaterial.roughness = roughness;

		if (!textures) {
			return defaultMaterial;
		}

		return [
			defaultMaterial,
			defaultMaterial,
			defaultMaterial,
			defaultMaterial,
			new MeshStandardMaterial({ wireframe, roughness, ...textures }),
			defaultMaterial,
		];
	}, [wireframe, textures, roughness]);

	return (
		<mesh {...meshProps}>
			<Geometry>
				<Base>
					<boxGeometry args={[width, height, thickness]} />
				</Base>
				{children}
			</Geometry>
			<primitive object={materials} attach="material" />
		</mesh>
	);
};

export default Wall;
