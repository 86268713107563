import { Environment } from "@react-three/drei";

export default function Lights() {
	return (
		<>
			{/* <spotLight shadow-intensity={2} castShadow position={[0, 6, 0]} intensity={10} /> */}
			<Environment files={["/hdri/potsdamer_platz_1k.jpg"]} />
		</>
	);
}
