import { TexturesType, setRepeatTextures, useDeferredTexture } from "@/hooks/useDeferredTexture";
import { useLeva } from "@/hooks/useLeva";
import { type MeshProps } from "@react-three/fiber";
import { FunctionComponent, useCallback, useMemo } from "react";
import { MeshStandardMaterial } from "three";

interface FloorProps extends MeshProps {
	width: number;
	depth: number;
	thickness: number;
}

const defaultMaterial = new MeshStandardMaterial({ color: "grey" });

const Floor: FunctionComponent<FloorProps> = ({ width, depth, thickness, ...meshProps }) => {
	const { wireframe, floorTexture, floorTextureRoughness: roughness, floorTextureRepeat: repeat } = useLeva();

	const onLoad = useCallback(
		(textures: TexturesType) => {
			setRepeatTextures(textures, width * repeat, depth * repeat);
		},
		[width, repeat, depth]
	);

	const textures = useDeferredTexture(floorTexture, onLoad);

	const materials = useMemo(() => {
		defaultMaterial.wireframe = wireframe;
		defaultMaterial.roughness = roughness;

		return [
			defaultMaterial,
			defaultMaterial,
			new MeshStandardMaterial({ wireframe, roughness, ...textures }),
			defaultMaterial,
			defaultMaterial,
			defaultMaterial,
		];
	}, [wireframe, textures, roughness]);

	return (
		<mesh {...meshProps}>
			<boxGeometry args={[width, thickness, depth]} />
			<primitive object={materials} attach="material" />
		</mesh>
	);
};

export default Floor;
