import { folder, useControls } from "leva";
import { Textures } from "./useDeferredTexture";

export const useLeva = () => ({
	...useControls("General", {
		showDoor: {
			value: true,
		},
		wireframe: { value: false },
	}),
	...useControls("Sauna", {
		saunaWidth: {
			value: 2,
			min: 0.5,
			max: 4,
			step: 0.1,
		},
		saunaDepth: {
			value: 1,
			min: 0.5,
			max: 4,
			step: 0.1,
		},
		saunaHeight: {
			value: 2,
			min: 1.8,
			max: 2.2,
			step: 0.05,
		},
	}),
	...useControls("Door", {
		doorWidth: {
			value: 1,
			min: 0.9,
			max: 1.8,
		},
		doorHeight: {
			value: 1.8,
			min: 1.8,
			max: 2,
		},
		shader: folder(
			{
				doorReflectivity: {
					value: 0.9,
					min: 0,
					max: 1,
					step: 0.05,
				},
				doorOpacity: {
					value: 0.2,
					min: 0,
					max: 1,
					step: 0.05,
				},
				doorRoughness: {
					value: 0.05,
					min: 0,
					max: 1,
					step: 0.05,
				},
			},
			{ collapsed: true }
		),
	}),
	...useControls("Floor", {
		floorThickness: {
			value: 0.02,
			min: 0.01,
			max: 0.04,
			step: 0.005,
		},
		shader: folder(
			{
				floorTexture: {
					value: Textures.MARBLE_FLOOR,
					options: Object.fromEntries(Object.entries(Textures).filter(([key]) => !["OAK"].includes(key))),
				},
				floorTextureRepeat: {
					value: 1,
					min: 0.5,
					max: 2,
				},
				floorTextureRoughness: {
					value: 0.5,
					min: 0,
					max: 1,
				},
			},
			{ collapsed: true }
		),
	}),
	...useControls("Walls", {
		wallThickness: {
			value: 0.02,
			min: 0.01,
			max: 0.04,
			step: 0.005,
		},
		shader: folder(
			{
				wallTextureRepeat: {
					value: 1,
					min: 0.5,
					max: 2,
				},
				wallTextureRoughness: {
					value: 0.5,
					min: 0,
					max: 1,
				},
			},
			{ collapsed: true }
		),
	}),
	...useControls("Bench", {
		benchDepth: {
			value: 0.6,
			min: 0.4,
			max: 0.8,
			step: 0.05,
		},
		benchHeight: {
			value: 0.45,
			min: 0.35,
			max: 0.55,
		},
		dividerWidth: {
			value: 0.1,
			min: 0,
			max: 0.2,
			step: 0.01,
		},
		plankThickness: {
			value: 0.025,
			min: 0.01,
			max: 0.05,
			step: 0.005,
		},
		seatMaxWidth: {
			value: 1,
			min: 0.5,
			max: 3,
			step: 0.1,
		},
		seatHorizontalCount: {
			value: 3,
			min: 1,
			max: 5,
			step: 1,
		},
		seatVerticalCount: {
			value: 3,
			min: 1,
			max: 5,
			step: 1,
		},
		seatHorizontalSpacing: {
			value: 0.005,
			min: 0,
			max: 0.02,
		},
		seatVerticalSpacing: {
			value: 0.005,
			min: 0,
			max: 0.02,
		},
		backRestCount: {
			value: 3,
			min: 1,
			max: 5,
			step: 1,
		},
		backRestSpacing: {
			value: 0.005,
			min: 0,
			max: 0.02,
		},
		backRestHeight: {
			value: 0.3,
			min: 0.1,
			max: 0.5,
		},
		backRestOffset: {
			value: 0.15,
			min: 0.05,
			max: 0.4,
		},
		bevelThickness: {
			value: 0,
			min: 0,
			max: 0.01,
		},
		shader: folder(
			{
				benchTextureRepeat: {
					value: 1,
					min: 0.25,
					max: 2,
				},
				benchTextureRoughness: {
					value: 0.5,
					min: 0,
					max: 1,
				},
			},
			{
				collapsed: true,
			}
		),
	}),
});
