import { type FunctionComponent } from "react";
import SpacedArray from "../../Utils/SpacedArray";
import Plank from "./Plank";
import { Vector3 } from "three";
import { useLeva } from "@/hooks/useLeva";
import { GroupProps } from "@react-three/fiber";

interface BackRestProps extends Omit<GroupProps, "args"> {
	width: number;
	height: number;
}

const BackRest: FunctionComponent<BackRestProps> = ({ width, height, ...groupProps }) => {
	const { plankThickness, backRestCount, backRestSpacing, bevelThickness } = useLeva();
	const plankWidth = height / backRestCount + backRestSpacing / backRestCount - backRestSpacing;

	const yOffset = (-height + plankWidth) / 2;
	const zOffset = plankThickness / 2;

	return (
		<group {...groupProps}>
			<SpacedArray count={backRestCount} offset={new Vector3(0, plankWidth + backRestSpacing, 0)}>
				{(position, i) => (
					<Plank
						key={`back-rest-board-${i.toString()}`}
						length={width}
						width={plankWidth}
						thickness={plankThickness}
						bevelThickness={bevelThickness}
						position={position.add({ x: 0, y: yOffset, z: zOffset })}
						rotation={[0, Math.PI / 2, Math.PI / 2]}
					/>
				)}
			</SpacedArray>
		</group>
	);
};

export default BackRest;
