import { useLeva } from "@/hooks/useLeva";
import { type FunctionComponent } from "react";
import { type GroupProps } from "@react-three/fiber";
import Seat from "./Seat";
import Divider from "./Divider";

interface BenchProps extends Omit<GroupProps, "args"> {
	width: number;
	height: number;
	depth: number;
}

const Bench: FunctionComponent<BenchProps> = ({ width, height, depth, ...groupProps }) => {
	const {
		dividerWidth,
		seatMaxWidth,
		seatHorizontalCount,
		seatVerticalCount,
		seatHorizontalSpacing,
		seatVerticalSpacing,
	} = useLeva();

	const N = Math.ceil((width + dividerWidth) / (seatMaxWidth + dividerWidth));
	const remainder = (width - (N - 2) * seatMaxWidth - (N - 1) * dividerWidth) / 2;
	const middle = (N - 1) / 2;

	const calcAvailable = (i: number): number =>
		N > 1 ? Math.min(seatMaxWidth, middle - Math.abs(i - middle) + remainder) : width;

	return (
		<group {...groupProps}>
			{Array(N)
				.fill(null)
				.flatMap((_, i) => {
					const spaceAvailable = calcAvailable(i);
					const offset = Array(i)
						.fill(null)
						.reduce<number>((result, _, i) => result + calcAvailable(i) + dividerWidth, -width / 2);

					return [
						<Seat
							key={`seat-${i.toString()}`}
							width={spaceAvailable}
							height={height}
							depth={depth}
							horizontalCount={seatHorizontalCount}
							verticalCount={seatVerticalCount}
							horizontalSpacing={seatHorizontalSpacing}
							verticalSpacing={seatVerticalSpacing}
							position={[offset + spaceAvailable / 2, 0, 0]}
						/>,
						...(i < N - 1 && dividerWidth > 0
							? [
									<Divider
										key={`divider-${i.toString()}`}
										width={dividerWidth}
										height={height}
										depth={depth}
										position={[offset + spaceAvailable + dividerWidth / 2, 0, 0]}
									/>,
								]
							: []),
					];
				})}
		</group>
	);
};

export default Bench;
