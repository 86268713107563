import { useLeva } from "@/hooks/useLeva";
import { type GroupProps } from "@react-three/fiber";
import { type FunctionComponent } from "react";
import Plank from "./Plank";

interface DividerProps extends Omit<GroupProps, "args"> {
	width: number;
	height: number;
	depth: number;
}

const Divider: FunctionComponent<DividerProps> = ({ width, height, depth, ...groupProps }) => {
	const { plankThickness, bevelThickness } = useLeva();
	return (
		<group {...groupProps}>
			<Plank
				length={depth}
				width={width}
				thickness={plankThickness}
				bevelThickness={bevelThickness}
				position={[0, (height - plankThickness) / 2, 0]}
			/>
			<Plank
				length={height - plankThickness}
				width={width}
				thickness={plankThickness}
				bevelThickness={bevelThickness}
				position={[0, -plankThickness / 2, (depth - plankThickness) / 2]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
		</group>
	);
};

export default Divider;
