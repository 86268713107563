import { useLeva } from "@/hooks/useLeva";
import { MeshProps } from "@react-three/fiber";
import { FunctionComponent } from "react";

interface RoofProps extends MeshProps {
	width: number;
	depth: number;
	thickness?: number;
}

const Roof: FunctionComponent<RoofProps> = ({ width, depth, thickness = 0.05, ...meshProps }) => {
	const { wireframe } = useLeva();

	return (
		<mesh {...meshProps}>
			<boxGeometry args={[width, thickness, depth]} />
			<meshStandardMaterial color="grey" wireframe={wireframe} />
		</mesh>
	);
};

export default Roof;
