import { useTexture } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useCallback, useDeferredValue } from "react";
import { LinearSRGBColorSpace, RepeatWrapping } from "three";

const TEXTURE_FOLDER = "textures";

enum Textures {
	DEBUG = "debug",
	OAK = "oak",
	MARBLE_FLOOR = "marble floor",
	TILE_FLOOR = "tile floor",
}

type TexturesType = ReturnType<typeof useTexture<ReturnType<typeof getMaps>>>;

const setRepeatTextures = (textures: TexturesType, s: number, t: number) => {
	for (const texture of Object.values(textures)) {
		texture.wrapS = texture.wrapT = RepeatWrapping;
		texture.repeat.set(s, t);
	}
};

const getMaps = (textureChoice: Textures): { map: string; normalMap?: string } => {
	switch (textureChoice) {
		case Textures.DEBUG:
			return { map: `/${TEXTURE_FOLDER}/debug.png` };
		case Textures.OAK:
			return {
				map: `/${TEXTURE_FOLDER}/oak_veneer_01_diff_2k.jpg`,
				normalMap: `/${TEXTURE_FOLDER}/oak_veneer_01_nor_gl_2k.jpg`,
			};
		case Textures.TILE_FLOOR:
			return {
				map: `/${TEXTURE_FOLDER}/floor_tiles_02_diff_2k.jpg`,
				normalMap: `${TEXTURE_FOLDER}/floor_tiles_02_nor_gl_2k.jpg`,
			};
		case Textures.MARBLE_FLOOR:
			return {
				map: `/${TEXTURE_FOLDER}/marble_tiles_diff_2k.jpg`,
				normalMap: `${TEXTURE_FOLDER}/marble_tiles_nor_gl_2k.jpg`,
			};
		default: {
			const guard: never = textureChoice;
			throw new Error(`Unhandled texture case: ${guard as string}`);
		}
	}
};

const useDeferredTexture = (textureName: Textures, onLoad?: (textures: TexturesType) => void) => {
	const gl = useThree((state) => state.gl);
	const anisotropy = gl.capabilities.getMaxAnisotropy();

	const maps = getMaps(textureName);
	const map = useDeferredValue(maps.map);
	const normalMap = useDeferredValue(maps.normalMap);
	const deferredTextures = { map, ...(normalMap ? { normalMap } : {}) };

	const _onLoad = useCallback(
		(textures: TexturesType) => {
			for (const texture of Object.values(textures)) {
				texture.flipY = false;
				texture.anisotropy = anisotropy;
			}

			onLoad?.(textures);
		},
		[anisotropy, onLoad]
	);

	const textures = useTexture(deferredTextures, _onLoad);

	if (textures.normalMap) {
		textures.normalMap.colorSpace = LinearSRGBColorSpace;
	}

	return textures;
};

export { Textures, type TexturesType, setRepeatTextures, useDeferredTexture };
