import { createFileRoute } from "@tanstack/react-router";
import styled from "styled-components";
import Scene from "@/components/three/Scene";

export const Route = createFileRoute("/_configurator")({
	component: Configurator,
});

function Configurator() {
	return (
		<ConfiguratorContainer>
			<Scene />
			{/* <Sidepanel>
				<Outlet />
			</Sidepanel> */}
		</ConfiguratorContainer>
	);
}

const ConfiguratorContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100svh;
`;
