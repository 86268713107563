import Slider from "@/components/primitives/Slider";
import { useAppStore } from "@/stores/appStore";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_configurator/step-1")({
	component: Step1,
});

function Step1() {
	const { saunaDimensions, update } = useAppStore();

	return (
		<div>
			<p>Step 1</p>
			<br />
			<h4>Dimensions</h4>

			<Slider
				label="Width"
				min={2.2}
				max={5}
				value={saunaDimensions.width}
				handleChange={(value) => update({ saunaDimensions: { ...saunaDimensions, width: value } })}
			/>
			<Slider
				label="Height"
				min={2}
				max={2.2}
				value={saunaDimensions.height}
				handleChange={(value) => update({ saunaDimensions: { ...saunaDimensions, height: value } })}
			/>
			<Slider
				label="Depth"
				min={0.5}
				max={1.5}
				value={saunaDimensions.depth}
				handleChange={(value) => update({ saunaDimensions: { ...saunaDimensions, depth: value } })}
			/>
		</div>
	);
}
