import { type FunctionComponent, Suspense, useCallback } from "react";
import Floor from "./Floor";
import Wall from "./Wall";
import { Subtraction } from "@react-three/csg";
import Bench from "./Bench";
import { type GroupProps } from "@react-three/fiber";
import { useLeva } from "@/hooks/useLeva";
import Roof from "./Roof";
import { setRepeatTextures, TexturesType, Textures } from "@/hooks/useDeferredTexture";
import BackRest from "./Bench/BackRest";
import ClonedTexture from "../Utils/ClonedTexture";

const Sauna: FunctionComponent<GroupProps> = (props) => {
	const {
		wireframe,
		showDoor,
		saunaWidth,
		saunaHeight,
		saunaDepth,
		doorWidth,
		doorHeight,
		doorReflectivity,
		doorOpacity,
		doorRoughness,
		benchHeight,
		benchDepth,
		backRestHeight,
		backRestOffset,
		floorThickness,
		wallThickness,
		wallTextureRepeat,
	} = useLeva();

	const roofThickness = floorThickness;
	const wallHeight = saunaHeight - (floorThickness + roofThickness);
	const innerWidth = saunaWidth - 2 * wallThickness;
	const innerDepth = saunaDepth - 2 * wallThickness;

	const doorGeometry = <boxGeometry args={[doorWidth, doorHeight, wallThickness]} />;

	const onLoadLeftRight = useCallback(
		(textures: TexturesType) => {
			setRepeatTextures(textures, saunaDepth * wallTextureRepeat, saunaHeight * wallTextureRepeat);
		},
		[saunaDepth, wallTextureRepeat, saunaHeight]
	);
	const onLoadFrontBack = useCallback(
		(textures: TexturesType) => {
			setRepeatTextures(textures, saunaWidth * wallTextureRepeat, saunaHeight * wallTextureRepeat);
		},
		[saunaWidth, wallTextureRepeat, saunaHeight]
	);

	return (
		<group {...props}>
			{/* Floor */}
			<Floor position={[0, floorThickness / 2, 0]} width={saunaWidth} depth={saunaDepth} thickness={floorThickness} />

			{/* Walls etc. */}
			<group position={[0, floorThickness, 0]}>
				{/* Bench */}
				<Suspense fallback={null}>
					<BackRest
						width={innerWidth}
						height={backRestHeight}
						position={[0, benchHeight + backRestHeight / 2 + backRestOffset, -innerDepth / 2]}
					/>
					<Bench
						width={innerWidth}
						height={benchHeight}
						depth={benchDepth}
						position={[0, benchHeight / 2, (-innerDepth + benchDepth) / 2]}
					/>
				</Suspense>

				<ClonedTexture textureName={Textures.OAK} onLoad={onLoadFrontBack}>
					{(textures) => (
						<>
							{/* Front wall and door */}
							<group position={[0, wallHeight / 2, (saunaDepth - wallThickness) / 2]}>
								{showDoor && (
									<mesh position={[0, -(wallHeight - doorHeight) / 2, 0]}>
										{doorGeometry}
										<meshPhysicalMaterial
											reflectivity={doorReflectivity}
											opacity={doorOpacity}
											roughness={doorRoughness}
											transparent
											wireframe={wireframe}
										/>
									</mesh>
								)}

								<Wall textures={textures} width={saunaWidth} height={wallHeight} thickness={wallThickness}>
									<Subtraction position={[0, (-wallHeight + doorHeight) / 2, 0]}>{doorGeometry}</Subtraction>
								</Wall>
							</group>

							{/* Back wall */}
							<Wall
								textures={textures}
								width={saunaWidth}
								height={wallHeight}
								position={[0, wallHeight / 2, -(saunaDepth - wallThickness) / 2]}
								thickness={wallThickness}
							/>
						</>
					)}
				</ClonedTexture>

				<ClonedTexture textureName={Textures.OAK} onLoad={onLoadLeftRight}>
					{(textures) => (
						<>
							{/* Left wall */}
							<Wall
								textures={textures}
								width={saunaDepth - 2 * wallThickness}
								height={wallHeight}
								position={[-(saunaWidth - wallThickness) / 2, wallHeight / 2, 0]}
								rotation={[0, Math.PI / 2, 0]}
								thickness={wallThickness}
							/>

							{/* Right wall */}
							<Wall
								textures={textures}
								width={saunaDepth - 2 * wallThickness}
								height={wallHeight}
								position={[(saunaWidth - wallThickness) / 2, wallHeight / 2, 0]}
								rotation={[0, -Math.PI / 2, 0]}
								thickness={wallThickness}
							/>
						</>
					)}
				</ClonedTexture>
			</group>

			{/* Roof */}
			<group position={[0, floorThickness + wallHeight, 0]}>
				<Roof position={[0, roofThickness / 2, 0]} width={saunaWidth} depth={saunaDepth} thickness={floorThickness} />
			</group>
		</group>
	);
};

export default Sauna;
