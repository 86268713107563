import { type GroupProps } from "@react-three/fiber";
import { type FunctionComponent } from "react";
import Plank from "./Plank";
import { useLeva } from "@/hooks/useLeva";
import SpacedArray from "../../Utils/SpacedArray";
import { Vector3 } from "three";

interface SeatProps extends Omit<GroupProps, "args"> {
	width: number;
	height: number;
	depth: number;
	horizontalCount?: number;
	verticalCount?: number;
	horizontalSpacing?: number;
	verticalSpacing?: number;
}

const Seat: FunctionComponent<SeatProps> = ({
	width,
	height,
	depth,
	horizontalCount = 1,
	verticalCount = 1,
	horizontalSpacing = 0,
	verticalSpacing = 0,
	...groupProps
}) => {
	const { plankThickness, bevelThickness } = useLeva();

	const seatPlankWidth = depth / horizontalCount + horizontalSpacing / horizontalCount - horizontalSpacing;
	const basePlankWidth =
		height / verticalCount + verticalSpacing / verticalCount - plankThickness / verticalCount - verticalSpacing;

	const seatYOffset = (height - plankThickness) / 2;
	const seatZOffset = (-depth + seatPlankWidth) / 2;

	const baseYOffset = (-height + basePlankWidth) / 2;
	const baseZOffset = (depth - plankThickness) / 2;

	return (
		<group {...groupProps}>
			{/* Seat */}
			<SpacedArray count={horizontalCount} offset={new Vector3(0, 0, seatPlankWidth + horizontalSpacing)}>
				{(position, i) => (
					<Plank
						key={`seat-board-${i.toString()}`}
						length={width}
						width={seatPlankWidth}
						thickness={plankThickness}
						bevelThickness={bevelThickness}
						rotation={[0, Math.PI / 2, 0]}
						position={position.add({ x: 0, y: seatYOffset, z: seatZOffset })}
					/>
				)}
			</SpacedArray>

			{/* Baseboards */}
			<SpacedArray count={verticalCount} offset={new Vector3(0, basePlankWidth + verticalSpacing, 0)}>
				{(position, i) => (
					<Plank
						key={`base-board-${i.toString()}`}
						length={width}
						width={basePlankWidth}
						thickness={plankThickness}
						bevelThickness={bevelThickness}
						rotation={[Math.PI / 2, Math.PI / 2, 0]}
						position={position.add({ x: 0, y: baseYOffset, z: baseZOffset })}
					/>
				)}
			</SpacedArray>
		</group>
	);
};

export default Seat;
