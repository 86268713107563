import { useCallback, type FunctionComponent } from "react";
import BeveledBox from "../../Utils/BeveledBox";
import { type MeshProps } from "@react-three/fiber";
import { useLeva } from "@/hooks/useLeva";
import { TexturesType, setRepeatTextures, useDeferredTexture, Textures } from "@/hooks/useDeferredTexture";

interface PlankProps extends Omit<MeshProps, "args"> {
	width: number;
	length: number;
	thickness: number;
	bevelThickness?: number;
}

const Plank: FunctionComponent<PlankProps> = ({ width, length, thickness, bevelThickness = 0, ...meshProps }) => {
	const { wireframe, benchTextureRoughness: roughness, benchTextureRepeat } = useLeva();

	const onLoad = useCallback(
		(textures: TexturesType) => {
			setRepeatTextures(textures, benchTextureRepeat, benchTextureRepeat);
		},
		[benchTextureRepeat]
	);
	const textures = useDeferredTexture(Textures.OAK, onLoad);

	return (
		<BeveledBox
			width={width - bevelThickness * 2}
			height={thickness - bevelThickness * 2}
			depth={length}
			bevelThickness={bevelThickness}
			{...meshProps}>
			<meshStandardMaterial metalness={0} roughness={roughness} wireframe={wireframe} {...textures} />
		</BeveledBox>
	);
};

export default Plank;
