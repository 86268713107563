import { FunctionComponent } from "react";
import styled from "styled-components";

interface SliderProps {
	label: string;
	min: number;
	max: number;
	value: number;
	handleChange: (value: number) => void;
}

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Slider: FunctionComponent<SliderProps> = ({ label, handleChange, ...props }) => {
	return (
		<Wrapper>
			<label htmlFor={label}>{label}</label>
			<input
				id={label}
				name={label}
				type="range"
				step={0.1}
				onChange={(event) => handleChange(event.target.valueAsNumber)}
				{...props}
			/>
		</Wrapper>
	);
};

export default Slider;
