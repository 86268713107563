import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { createJSONStorage, persist } from "zustand/middleware";

const isDevMode = import.meta.env.DEV;

type State = {
	saunaDimensions: {
		width: number;
		height: number;
		depth: number;
		wallThickness: number;
		floorThickness: number;
		roofThickness: number;
	};
	doorDimensions: {
		width: number;
		height: number;
	};
	isDevMode: boolean;
};

type Actions = {
	update: (options: Partial<State>) => void;
};

export const useAppStore = create(
	persist<State & Actions>(
		(set) => ({
			saunaDimensions: {
				width: 2.5,
				height: 2,
				depth: 1,
				wallThickness: 0.05,
				floorThickness: 0.05,
				roofThickness: 0.05,
			},
			doorDimensions: {
				width: 2,
				height: 1.8,
			},
			isDevMode,
			update: (options) => set((state) => ({ ...state, ...options })),
		}),
		{
			name: "app-store",
			version: 1,
			storage: createJSONStorage(() => sessionStorage),
		}
	)
);

if (isDevMode) {
	mountStoreDevtool("AppStore", useAppStore);
}
